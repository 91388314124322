<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <PageHeader :title="title" :items="items" class="poppins fw-normal ps-3 pt-3" />
          <div class="card-body table">

            <div class="main_header_ctas d-flex flex-wrap gap-2 align-items-center justify-content-between">
             
                <div :class="{'buttons_wrapper': true,  'border-orange rounded-pill p1': multipuleIds?.length >= 1}"> 
                <button v-if="multipuleIds?.length >= 1" @click="deleteBulk" class="
                                                                   btn
                                                                   border-0
                                                                   px-5
                                                                   fw-bold
                                                                   text-white
                                                                   btn btn-primary
                                                                   waves-effect waves-light
                                                                   w-md
                                                                   primary-button
                                                                   rounded-pill
                                                                 ">
                  Delete All
                </button>
              </div>
               <div :class="{'buttons_wrapper':true,'border-orange rounded-pill p1': multipuleIds?.length >= 1}"> 
                <button v-if="multipuleIds?.length >= 1" @click="approveBulk" class="
                                                                   btn
                                                                   border-0
                                                                   px-5
                                                                   fw-bold
                                                                   text-white
                                                                   btn btn-primary
                                                                   waves-effect waves-light
                                                                   w-md
                                                                   primary-button
                                                                   rounded-pill
                                                                 ">
                  Approve All
                </button>
              </div>
               <div :class="{'buttons_wrapper': true,'border-orange rounded-pill p1': mergListingbtn}"> 
                <button v-if="mergListingbtn" @click="listingMerge" class="
                                                                  btn
                                                                  border-0
                                                                  fw-bold
                                                                  text-white
                                                                  btn btn-primary
                                                                  waves-effect waves-light
                                                                  w-md
                                                                  primary-button
                                                                  rounded-pill
                                                                ">
                  Merge All
                </button>
              </div>
              <div class="buttons_wrapper p1 rounded-pill border-orange fit-content"> <router-link to="/business/add" class="
                                                            rounded-pill btn border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button
                                                                ">
                  Add New Bussiness
                </router-link></div>
              <div class="d-flex justify-content-between w-100">
                <div class="plan_filter pe-2 py-0">
                  <select @change="allBusiness($event)" class="form-select table-light pe-5"
                    id="floatingSelectGrid" aria-label="select">
                    <option value="all" selected="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Accepted">Accepted</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Sponsored">Sponsored</option>

                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable" class="table table-centered table-nowrap mb-0 w-100">
                <thead class="table-light bussiness_listing_view">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input @change="selectAllRecord" v-model="selectAll" type="checkbox" class="form-check-input"
                          id="customCheck1" />
                        <label class="form-check-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <th>Business Name</th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Social Links</th>
                    <th>Status</th>
                    <th>Sponsored Status</th>
                    <th>Address</th>
                    <th>Business Plan</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="11">
                      <APILoader :loading="loading" class="align-middle text-center"></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(list, index) in listing" v-bind:key="index">
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input type="checkbox" class="form-check-input" v-model="multipuleIds" :value="list.id"
                          id="customCheck1" @change="mergList($event, list)" />
                        <label class="form-check-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <td>
                      <span>{{ list.title }}</span>
                    </td>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <div v-if="list.user">
                            <img v-if="!list.user.avatar" src="../../../../../public/avatar.jpg" class="user-img me-2"
                              alt="..." width="30px" height="30px" />
                            <img v-else :src="list.user.avatar" class="user-img me-2" alt="..." width="30px"
                              height="30px">
                          </div>

                          <!-- {{ list }} -->
                          <span>{{
                            getSafe(() => list.user.full_name, "N/A")
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ list.mobile == null ? "N/A" : list.mobile }}</td>
                    <td>{{ list.email == null ? "N/A" : list.email }}</td>
                    <td>
                      <a href class="text-dark pe-1">
                        <i class="uil-facebook-f"></i>
                      </a>
                      <a href class="text-dark pe-1">
                        <i class="uil-twitter"></i>
                      </a>
                      <a href class="text-dark pe-1">
                        <i class="uil-instagram"></i>
                      </a>
                      <a href class="text-dark pe-1">
                        <i class="bx bxl-google"></i>
                      </a>
                    </td>
                    <td>
                      <div v-if="list.status == 'Pending'" class="d-flex justify-content-start gap-2">
                        <a href="javascript: void(0);" @click="statusChange(list.id, 'Rejected')"><i data-toggle="tooltip"
                            title="Reject" class="uil-times-circle f-24 text-danger"></i></a>
                        <a href="javascript: void(0);" @click="statusChange(list.id, 'Accepted')"><i data-toggle="tooltip"
                            title="Accept" class="uil-check-circle f-24 text-info"></i></a>

                      </div>
                      <div v-else-if="list.status == 'Accepted'">
                        <span class="plan_cta success_cta">Accepted</span>
                      </div>
                      <div v-else>
                        <span class="plan_cta rejected_cta">Rejected</span>
                      </div>
                    </td>
                    <td>

                      <div class="form-check form-switchs p-2 d-flex">
                        <label class="switch m-0">
                          <input :disabled="list.status === 'Pending'" @change="sponsership(index, $event)"
                            v-model="list.sponsorship" class="form-check-input" type="checkbox"
                            :id="'flexSwitchCheckDefault' + index" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </td>
                    <td>{{ getSafe(() => list.address.city, "N/A") }}</td>
                    <td class="text-center">
                      <span v-if="list.subscription?.length < 1" class="plan_pro plan_cta">Basic</span>
                      <span v-else class="plan_pro plan_cta">Pro</span>
                    </td>
                    <td>
                      <div class="d-flex">
                        <button style="margin: 10px" @click="listingUpdate(list.id)" class="p-0 bg-transparent border-0">
                          <span class="text-primary">
                            <i class="uil-edit"></i>
                          </span>
                        </button>
                        <button @click="deleteListing(list.id)" class="p-0 bg-transparent border-0">
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <Pagination /> -->
              <!-- <Pagination :links="links" @next="allBusiness" /> -->
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import { slotFlagsText } from "@vue/shared";

export default {
  page: {
    title: "Business Listing",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      mergListingbtn: false,
      selectedBusiness: [],
      selectAll: false,
      processing: false,
      status: null,
      loading: true,
      listing: null,
      title: "Business Listing",
      links: [],
      multipuleIds: [],
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Business Listing",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allBusiness();
  },
  methods: {
    randerData(response) {
      this.listing = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          scrollX: true,
          fixedHeader: {
            header: true,
            footer: true
          },
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allBusiness(val) {
      let filterValue = val?.target?.value;
      this.$axios
        .get("all-listing", { params: { filterValue } })
        .then((response) => {
          console.log(response);
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    getRecords(link) {
      console.log(link);
    },
    async listingDeleted(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("delete-business-listing/" + id);
        this.responselistingDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteListing(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.listingDeleted(id);
          let status = this.responselistingDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.allBusiness();
          }
        }
      });
    },
    listingUpdate: function (id) {
      this.$router.push({
        name: "update-list",
        params: { id: id },
      });
    },
    statusChange(id, status) {
      let data = {
        status: status,
        vendor_listing_id: id,
      };
      this.swalAlert(data, status);


    },

    swalAlert(data, status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiForStatusChange(data, status);
          Swal.fire("Updated!", "Status has been Changed.", "success");

        }
      });
    },

    apiForStatusChange(data, status) {
      if (status == "rejected") {
        this.rejectListing(data);
      } else {
        this.$axios
          .post("vendor-listing-status", data)
          .then((response) => {
            this.allBusiness();
            this.triggerSwal(response.data.data, "success");
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    sponsership(index) {
      let singleRecord = this.listing[index];
      this.$axios
        .post("change-sponsored-ship", {
          id: singleRecord.id,
          sponsorship: singleRecord.sponsorship,
        })
        .then((response) => {
          console.log(response.data);
          this.triggerSwal(response.data.message, "success");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    rejectListing(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios
            .post("vendor-listing-status", data)
            .then((response) => {
              this.allBusiness();
            })
            .catch((error) => {
              console.log(error.response);
            });
          Swal.fire("Updated!", "Status has been Changed.", "success");
          this.allBusiness();
        }
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.listing.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    approveBulk() {
      this.$axios
        .post("approve-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mergList(event, businessName) {
      if (event.target.checked) {
        if (this.selectedBusiness?.length == 0) {
          this.selectedBusiness.push(businessName);
          // this.mergListingbtn=true;
        }
        else {
          let status = this.selectedBusiness.find(e => e.title == businessName.title);
          if (status) {
            this.selectedBusiness.push(businessName);
            this.mergListingbtn = true;
          }
          else {
            this.mergListingbtn = false;
          }
        }
      } else {
        this.selectedBusiness.splice(businessName, 1);
        this.selectedBusiness?.length <= 1 ? this.mergListingbtn = false : this.mergListingbtn = true
      }
    },
    listingMerge() {
      this.$axios.post('/merge-listings', this.selectedBusiness).then(response => {
        this.triggerSwal(response.data.message, "success");
        this.mergListingbtn = false;
        this.allBusiness();
      }).catch(error => {
        console.log(error.response);
      })
    }
  },
};
</script>